import { FC, useState } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

import InputValidation from 'components/form-parts/input-validation-react/input-validation-react'

import { FieldsNameVoc } from 'constants/auth/profile-data'
import { STATIC_URL } from 'constants/data'

import styles from './form-password-react.module.scss'

const imgPath = {
  visibility: `${STATIC_URL}/auth/visibility.svg`,
  visibilityOff: `${STATIC_URL}/auth/visibility_off.svg`,
}

interface IFormPassword extends React.InputHTMLAttributes<HTMLInputElement> {
  error: any
  inputRef: any
  fieldKey?: string
  name?: string
  noName?: boolean
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  modificator?: string
  labelModificator?: string
  inputNameModificator?: string
  innerContainerModificator?: string
  inputWrapperModificator?: string
  errorModificator?: string
  value?: string
  dynamicName?: boolean
  initInput?: () => void
  errorWithoutText?: boolean
}

const FormPassword: FC<IFormPassword> = ({
  error,
  inputRef,
  fieldKey,
  name,
  noName,
  onClick,
  modificator,
  labelModificator,
  inputNameModificator,
  innerContainerModificator,
  inputWrapperModificator,
  ...props
}: IFormPassword) => {
  const [isShowPassword, setShowPassword] = useState(false)

  const togglePassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setShowPassword(!isShowPassword)
  }
  const setInputRef = (element: HTMLInputElement) => {
    inputRef.current = element
  }
  return (
    <>
      <InputValidation
        nameInput={name ? name : 'Пароль'}
        name={name ? name : FieldsNameVoc.password}
        error={error}
        noName={noName}
        modificator={clsx(styles.inputMod, {
          [styles.password]: !isShowPassword,
          [modificator as string]: modificator,
        })}
        labelModificator={labelModificator}
        inputNameModificator={inputNameModificator}
        innerContainerModificator={innerContainerModificator}
        inputWrapperModificator={inputWrapperModificator}
        onClick={onClick}
        setInputRef={setInputRef}
        {...props}
        type={isShowPassword ? 'text' : 'password'}
      >
        <button className={styles.togglePasswordVisibility} onClick={togglePassword}>
          <Image
            src={isShowPassword ? imgPath.visibility : imgPath.visibilityOff}
            alt="show password"
            width="24"
            height="24"
          />
        </button>
      </InputValidation>
      <input type="password" className={styles.hidden} readOnly />
    </>
  )
}

export default FormPassword
